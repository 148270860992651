import xhr from './xhr/';
/**
 * 用户相关的 API
 */
class AccountService {
	/**
	 * 登录
	 * @param  {String} userData.username
	 * @return {Object} userData
	 */
	login(userData) {
		return xhr({
			method: 'post',
			url: '/login',
			params: userData,
		});
	}

	/**
	 * 修改信息
	 * @param  {Object} personData
	 * @return {Object} personData
	 */
	personModify(personData) {
		return xhr({
			method: 'post',
			url: '/setting/personal/modify',
			params: personData,
		});
	}

	/**
	 * 修改密码
	 */
	pwdModify(password) {
		return xhr({
			method: 'post',
			url: '/password/modify',
			params: password,
		});
	}

	/**
	 * 获取会员信息
	 */
	getInfo(id) {
		return xhr({
			method: 'get',
			url: '/staff/get_info',
			params: id,
		});
	}

	/**
	 * 获取会员首页
	 */
	getHomeResult(params) {
		return xhr({
			method: 'post',
			url: '/member/mainpage',
			params,
		});
	}

	/**
	 * 获取首页门店
	 */
	getHomeBranch(params) {
		return xhr({
			method: 'get',
			url: '/common/branchlist',
			params,
		});
	}

	/**
	 * 获取会员首页
	 */
	staffIndex(id) {
		return xhr({
			method: 'get',
			url: '/staff/index',
			params: id,
		});
	}
}

// 实例化后导出，全局单例
export default new AccountService();
